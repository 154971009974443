import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  FormProps,
} from "antd";
import { PlusOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslations } from "next-intl";
import SelectWithIcon from "@/components/shared/SelectWithIcon";
import { CustomerInformationSlugEnum } from "@/services/types";
import useCreateContact from "@/services/contact/useCreateContact";
import { ContactContext } from "../ContactContext";
import useGetAllProperty from "@/services/property/useGetAllProperty";
import groupBy from "lodash/groupBy";
import {
  AccountManagerSelect,
  CustomerLifeCycleSelect,
  LabelSelect,
  LeadSourceSelect,
  SupportStaffSelect,
} from "./CreateContactSelect";
import GuardedComponent from "@/components/shared/403/GuardedComponent";
import ContactForm from "./ContactForm";
import { KANBAN_ACTIONS, kanbanDataPubSub } from "./kanban/kanbanDataPubSub";

type FieldType = Record<string, string | string[]>;

interface CreateContactButtonProps {
  ui: "list_contact" | "phone_window";
  defaultValues?: FieldType;
  callback?: () => void;
}

export const CreateContactButton = ({
  ui = "list_contact",
  defaultValues,
  callback,
}: CreateContactButtonProps) => {
  const { mutate, isPending, isSuccess, reset, data } = useCreateContact();
  const t = useTranslations("contacts");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { setIsCreateContactSuccess } = useContext(ContactContext);
  const { data: allProperty } = useGetAllProperty("contact");

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    const submitField: Record<string, string | string[]> = {};
    for (const [key, value] of Object.entries(values)) {
      if (value) {
        submitField[key] = value;
      }
    }
    mutate(submitField, {
      onSuccess: (res) => {
        handleCancel();
        setIsCreateContactSuccess(true);
        if (res.data) {
          const customerLifecycle = res.data?.properties?.find(
            (item) => item.slug === "customer_lifecycle"
          )?.value as string;
          if (customerLifecycle) {
            kanbanDataPubSub.publish(
              KANBAN_ACTIONS.insert(customerLifecycle.replaceAll(" ", "_")),
              [res.data]
            );
          }
        }
        callback && callback();
      },
    });
  };

  return (
    <>
      <GuardedComponent requiredPermissions={["contact-create"]}>
        {ui === "list_contact" && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalOpen(true)}>
            Thêm khách hàng
          </Button>
        )}
        {ui === "phone_window" && (
          <Button
            className="mb-2"
            size="small"
            onClick={() => setIsModalOpen(true)}
            style={{ marginBottom: "8px" }}
            icon={<UserAddOutlined />}
            type="primary"
            block
            ghost>
            Thêm
          </Button>
        )}
      </GuardedComponent>

      <Modal
        title={
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t("add_contact.new")}
          </Typography.Title>
        }
        styles={{ body: { maxWidth: 730 } }}
        classNames={{ body: "container-md" }}
        open={isModalOpen}
        confirmLoading={isPending}
        style={{ padding: 0 }}
        onCancel={handleCancel}
        cancelText={t("add_contact.cancel")}
        okText={t("add_contact.confirm")}
        onOk={() => form.submit()}
        okType="primary">
        <ContactForm
          initialValues={defaultValues}
          onFinish={onFinish}
          form={form}
          propertyList={allProperty?.data ? allProperty?.data : []}
        />
      </Modal>
    </>
  );
};

export default CreateContactButton;
