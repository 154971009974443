import { AxiosResponse } from "axios";
import { IPaginate, OptionValueType, ServerFormError } from "../types";
import { useQuery } from "@tanstack/react-query";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";

export const getListOptionValueQueryKey = "list_option_value";
function useGetOptionValue(propertyId: string, search?: string) {
  const { axiosInstance, API_ROUTES_COMPANY, COMPANY_ID } = useGlobalAppState();

  return useQuery<AxiosResponse<IPaginate<OptionValueType>>, ServerFormError>({
    queryKey: [getListOptionValueQueryKey, COMPANY_ID, propertyId, search],
    queryFn: () => {
      return axiosInstance.get(
        API_ROUTES_COMPANY.property.list_option_value(propertyId),
        search
          ? { params: { search, page: 1, size: 1000 } }
          : { params: { page: 1, size: 1000 } }
      );
    },
    enabled: COMPANY_ID !== undefined && propertyId !== "",
    retry: 1,
    refetchOnWindowFocus: 'always',
  });
}

export default useGetOptionValue;
