import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import {
  FilterObject,
  OrderColumnType,
  ServerFormError,
  IPaginate,
  EnterpriseType,
} from "../types";
import { PaginationInputs } from "@/services/usePaginationAPI";

const filterEnterpriseKey = "filter_enterprise";

const useFilterEnterprise = ({
  pagination,
  currentFilters,
}: PaginationInputs) => {
  const { axiosInstance, API_ROUTES_COMPANY, COMPANY_ID } = useGlobalAppState();

  return useQuery<AxiosResponse<IPaginate<EnterpriseType>>, ServerFormError>({
    queryKey: [filterEnterpriseKey, COMPANY_ID, pagination, currentFilters],
    queryFn: () => {
      const queryBody = {
        properties: currentFilters.properties || [
          "company_code",
          "company_name",
          "company_email",
          "company_phone",
          "company_category",
          "company_description",
          "company_short_name",
          "company_address",
          "company_foreign_name",
          "company_website",
          "company_primary_contact",
          "company_contacts",
        ],
        filter_groups: currentFilters.filterGroups || {
          filter_type: "AND",
          filters: [],
        },
        order_column: currentFilters.orderColumn || {},
      };
      return axiosInstance.post(
        API_ROUTES_COMPANY.enterprise.filter_enterprise,
        queryBody,
        {
          params: {
            ...pagination,
            size: pagination?.page_size,
            page_size: undefined,
            search: currentFilters.search,
          },
        }
      );
    },
    enabled: COMPANY_ID !== undefined,
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

export default useFilterEnterprise;
