import { useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { IPaginate, ServerFormError } from "../types";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { PaginationInputs } from "@/services/usePaginationAPI";
import { ICompanyprofile } from "@/services/profile/useNewCompanyProfile";

export const useCompanyProfilesKey = "company-profiles";

function useCompanyProfiles({
  pagination,
  enabled = true,
  currentFilters,
}: PaginationInputs & { search?: string; enabled?: boolean }) {
  const { axiosInstance, COMPANY_ID, API_ROUTES_COMPANY } = useGlobalAppState();
  return useQuery<AxiosResponse<IPaginate<ICompanyprofile>>, ServerFormError>({
    queryKey: [useCompanyProfilesKey, COMPANY_ID, currentFilters, pagination],
    queryFn: () => {
      return axiosInstance.get(API_ROUTES_COMPANY.companyprofile.list, {
        params: {
          ...pagination,
          size: pagination?.page_size,
          ...currentFilters,
        },
      });
    },
    enabled: enabled,
  });
}

export default useCompanyProfiles;
