import { useCallback, useState } from "react";
import debounce from "lodash/debounce";

export const useDebouncedSearch = (initSearch?: string, time: number = 500) => {
  const [search, setSearch] = useState<string | undefined>(initSearch);
  const onSearch = useCallback(
    debounce((search: string | undefined) => {
      setSearch(search);
    }, time),
    []
  );

  return { search, onSearch };
};
