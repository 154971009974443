import React, { ReactNode } from "react";
import { Select, SelectProps } from "antd";
import styled from "styled-components";

type SelectWithIconProps = SelectProps & {
  prefixIcon?: ReactNode;
};

const SelectWrapper = styled.div`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    left: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #8c8c8c !important;
  }

  && .ant-select .ant-select-selector {
    padding-left: 38px;
    padding-right: 20px;
  }

  && .ant-select .ant-select-selector .ant-select-selection-placeholder {
    font-size: 14px;
    color: #8c8c8c !important;
    font-weight: 400;
    padding-right: 6px;
  }

  &&
    .ant-select-multiple
    .ant-select-selector
    .ant-select-selection-placeholder {
    font-size: 14px;
    color: #8c8c8c !important;
    font-weight: 400;
    padding-right: 6px;
    margin-left: 28px;
  }

  && .ant-select .ant-select-selector .ant-select-selection-item {
    font-size: 14px;
    padding-right: 8px;
  }

  && .ant-select .ant-select-arrow {
    font-size: 10px;
    color: #8c8c8c !important;
    right: 14px;
  }
`;

const SelectWithIcon = ({
  prefixIcon,
  children,
  ...rest
}: SelectWithIconProps) => {
  return (
    <>
      {prefixIcon ? (
        <SelectWrapper>
          <div className="prefix-icon-wrapper">{prefixIcon}</div>
          <Select {...rest}>{children}</Select>
        </SelectWrapper>
      ) : (
        <Select {...rest}>{children}</Select>
      )}
    </>
  );
};

export default SelectWithIcon;
