import { Tooltip } from "antd";
import useHasPermission from "@/hooks/app/useHasPermission";
import React from "react";
import { useGlobalConfig } from "@/providers/app/GlobalConfigProvider";

interface GuardedComponentProps {
  children: React.ReactNode;
  requiredPermissions: string[];
  operator?: "AND" | "OR";
  wrapper?: (children) => React.ReactNode;
}

const GuardedComponent = ({
  children,
  requiredPermissions,
  operator = "OR",
  wrapper,
}: GuardedComponentProps) => {
  const enabled = useHasPermission(requiredPermissions);

  if (requiredPermissions.length === 0) {
    return children;
  }

  if (
    (operator === "AND" && enabled.includes(false)) ||
    (operator === "OR" && enabled.every((permission) => !permission))
  ) {
    if (wrapper) {
      return (
        <Tooltip title="Bạn không có quyền truy cập" color="red">
          {wrapper(children)}
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Bạn không có quyền truy cập" color="red">
        <span style={{ opacity: 0.3 }}>
          <span style={{ pointerEvents: "none" }}>{children}</span>
        </span>
      </Tooltip>
    );
  }

  return children;
};

export default GuardedComponent;
