import { AxiosResponse } from "axios";
import { PropertyType, ServerFormError } from "../types";
import { useQuery } from "@tanstack/react-query";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";

export const getAllPropertyQueryKey = "all_property";
function useGetAllProperty(modeName: string) {
  const { axiosInstance, API_ROUTES_COMPANY, COMPANY_ID } = useGlobalAppState();

  return useQuery<AxiosResponse<PropertyType[]>, ServerFormError>({
    queryKey: [getAllPropertyQueryKey, COMPANY_ID, modeName],
    queryFn: () => {
      return axiosInstance.get(
        API_ROUTES_COMPANY.property.get_all_property(modeName)
      );
    },
    enabled: COMPANY_ID !== undefined && modeName !== '',
    retry: 1,
    refetchOnWindowFocus: false,
  });
}

export default useGetAllProperty;
