import { AxiosResponse } from "axios";
import { IProfile, ServerFormError } from "../types";
import { useQuery } from "@tanstack/react-query";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";

export const GetCompanyProfileKey = "companyprofileraw";
function useGetCompanyProfile(search?: string) {
  const { axiosInstance, API_ROUTES_COMPANY, COMPANY_ID } = useGlobalAppState();

  return useQuery<AxiosResponse<IProfile[]>, ServerFormError>({
    queryKey: [GetCompanyProfileKey, COMPANY_ID, search],
    queryFn: () => {
      return axiosInstance.get(
        API_ROUTES_COMPANY.profile.companyprofileraw,
        search ? { params: { search: search } } : {}
      );
    },
    enabled: COMPANY_ID !== undefined,
    retry: 1,
  });
}

export default useGetCompanyProfile;
