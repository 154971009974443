import type { ThemeConfig } from "antd";

const defaultTheme: ThemeConfig = {
  token: {
    fontSize: 14,
    colorPrimary: "rgba(30, 39, 109, 1)",
  },
  components: {
    Segmented: {
      trackBg: '#f0f0f0'
    },
  },
  cssVar: true,
};

export default defaultTheme;
