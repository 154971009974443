import type { AxiosResponse } from "axios";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { useQuery } from "@tanstack/react-query";
import { IAccessPermissionList, ServerFormError } from "@/services/types";

export const useAllRawAccessPermissionsKey = "all-raw-access-permissions";

function useAllRawAccessPermissions() {
  const { axiosInstance, API_ROUTES, COMPANY_ID } = useGlobalAppState();
  return useQuery<AxiosResponse<IAccessPermissionList>, ServerFormError>({
    queryKey: [useAllRawAccessPermissionsKey, COMPANY_ID],
    queryFn: () => {
      return axiosInstance.get(API_ROUTES.access_permission.raw);
    },
    refetchOnWindowFocus: false,
  });
}

export default useAllRawAccessPermissions;
