import { AxiosError, AxiosResponse } from "axios";
import {
  ContactType,
  ContactViewType,
  DefaultServerError,
  ServerFormError,
} from "../types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { message } from "antd";
import { useTranslations } from "next-intl";

function useCreateContact() {
  const t = useTranslations("contacts.add_contact");
  const { axiosInstance, API_ROUTES_COMPANY } = useGlobalAppState();

  return useMutation<
    AxiosResponse<ContactType>,
    AxiosError<DefaultServerError>,
    Record<string, string | string[]>
  >({
    mutationFn: (contact) => {
      return axiosInstance.post(
        API_ROUTES_COMPANY.contact.create_contact,
        contact
      );
    },
    onSuccess: () => {
      message.success(t("create_success"));
    },
    onError: (e) => {
      console.log(e);
      const error_messages = e.response?.data.error_messages;
      const non_field_errors = e.response?.data.non_field_errors;
      if (error_messages) {
        message.error(`${error_messages}`);
      } else {
        message.error(t("upload_error"));
      }

      if (non_field_errors) {
        non_field_errors.forEach((error) => {
          message.error(`${error}`);
        });
      }
    },
  });
}

export default useCreateContact;
