import type { AxiosResponse } from "axios";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { useQuery } from "@tanstack/react-query";
import { IMyCompanyProfile, ServerFormError } from "@/services/types";
import { message } from "antd";

export const useMyCompanyProfileKey = "my-company";

function useMyCompanyProfile() {
  const { axiosInstance, API_ROUTES_COMPANY, COMPANY_ID } = useGlobalAppState();
  return useQuery<AxiosResponse<IMyCompanyProfile>, ServerFormError>({
    queryKey: [useMyCompanyProfileKey, COMPANY_ID],
    queryFn: () => {
      return axiosInstance.get(API_ROUTES_COMPANY.companyprofile.me);
    },
    refetchOnWindowFocus: true,
    retry: false,
    // throwOnError: (error) => {
    //   message.error({
    //     key: "cannot-fetch-company-profile",
    //     content:
    //       "Không thể kiểm tra thông tin tài khoản & quyền hạn. Tính năng có thể bị giới hạn",
    //   });
    //   return false;
    // },
  });
}

export default useMyCompanyProfile;
