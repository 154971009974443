import { ContactType } from "@/services/types";
import { differenceBy, filter, intersectionBy, keyBy, property } from "lodash";

export const kanbanDataPubSub = {
  events: {} as { [key in string]: any },
  subscribe(event: string, callback: any) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  publish(event: string, data: any) {
    if (this.events[event])
      this.events[event].forEach((callback: any) => callback(data));
  },
};

export const KANBAN_ACTIONS = {
  trigger: `action-trigger`,
  insert: (sectionId: string) => `insert-${sectionId}`,
  removeBySectionId: (sectionId: string) => `remove-${sectionId}`,
  remove: `remove`,
  removeAll: (sectionId: string) => `remove-all-${sectionId}`,
  update: `update`,
  updateCustomerLifeCycle: `updateCustomerLifeCycle`,
};

export function insertContacts(
  contacts: ContactType[],
  oldData: ContactType[]
) {
  kanbanDataPubSub.publish(KANBAN_ACTIONS.trigger, undefined);
  return [...contacts, ...oldData];
}

export function removeContacts(contactIds: string[], oldData: ContactType[]) {
  kanbanDataPubSub.publish(KANBAN_ACTIONS.trigger, undefined);
  return filter(oldData, (item) => !contactIds.includes(item.id));
}

export function removeAllContacts() {
  kanbanDataPubSub.publish(KANBAN_ACTIONS.trigger, undefined);
  return [];
}

export function updateCustomerLifeCycle(
  sectionId: string,
  toSectionId: string,
  contactIds: string[],
  oldData: ContactType[],
  contacts: ContactType[]
) {
  kanbanDataPubSub.publish(KANBAN_ACTIONS.trigger, undefined);
  if (sectionId === toSectionId) {
    const addContacts = differenceBy(contacts, oldData, "id");
    return [...addContacts, ...oldData];
  } else {
    return filter(oldData, (item) => !contactIds.includes(item.id));
  }
}

export function updateContacts(
  updateList: { [key: string]: ContactType },
  oldData: ContactType[]
) {
  kanbanDataPubSub.publish(KANBAN_ACTIONS.trigger, undefined);
  return oldData.map((item) => {
    return updateList[item.id] || item;
  });
}
