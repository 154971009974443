export const INPUT_FILTER_TYPES = [
  "formulaproperty",
  "singlelineproperty",
  "phoneproperty",
  "integerproperty",
  "richtextproperty",
  "multilineproperty",
];
export const SELECT_FILTER_TYPES = [
  "associateproperty",
  "singleoptionproperty",
  "multioptionproperty",
];

export const NUMBER_OPERATORS = [
  { value: "EQUAL", label: "Bằng với" },
  { value: "NOT_EQUAL", label: "Khác với" },
  { value: "GREATER", label: "Lớn hơn với" },
  { value: "GREATER_EQUAL", label: "Lớn hơn hoặc bằng với" },
  { value: "LESS", label: "Nhỏ hơn với với" },
  { value: "LESS_EQUAL", label: "Nhỏ hơn hoặc bằng với" },
  { value: "VALUABLE", label: "Có giá trị" },
  { value: "EMPTY", label: "Rỗng" },
];

export const TEXT_PROPERTY = [
  "singlelineproperty",
  "formulaproperty",
  "phoneproperty",
  "multilineproperty",
  "richtextproperty",
];

export const TEXT_OPERATORS = [
  { value: "EQUAL", label: "Bằng với" },
  { value: "NOT_EQUAL", label: "Khác với" },
  { value: "CONTAIN", label: "Có chứa" },
  { value: "NOT_CONTAIN", label: "Không chứa" },
  { value: "VALUABLE", label: "Có giá trị" },
  { value: "EMPTY", label: "Rỗng" },
];

export const TIME_OPERATORS = [
  { value: "EQUAL", label: "Bằng với" },
  { value: "GREATER_EQUAL", label: "Sau với" },
  { value: "LESS_EQUAL", label: "Trước với" },
  { value: "VALUABLE", label: "Có giá trị" },
  { value: "EMPTY", label: "Rỗng" },
];

// export const DATA_TYPE: Record<string, string> = {
//   customer_code: "formulaproperty",
//   customer_name: "singlelineproperty",
//   account_manager: "singleuserproperty",
//   support_staff: "multiuserproperty",
//   phone_number: "phoneproperty",
//   email: "singlelineproperty",
//   date_of_birth: "dateproperty",
//   address: "singlelineproperty",
//   city: "singlelineproperty",
//   district: "singlelineproperty",
//   commune: "singlelineproperty",
//   company: "associateproperty",
//   department: "singlelineproperty",
//   position: "singlelineproperty",
//   customer_lifecycle: "singleoptionproperty",
//   label: "singleoptionproperty",
//   lead_source: "singleoptionproperty",
//   first_conversation: "singleoptionproperty",
//   init_time: "dateproperty",
//   first_purchase_time: "dateproperty",
//   latest_purchase_time: "dateproperty",
//   first_interaction_time: "dateproperty",
//   latest_interaction_time: "dateproperty",
//   next_interaction_time: "dateproperty",
//   number_employee_interactions: "integerproperty",
// };

export const DEFAULT_FORM_VALUE = {
  filter_type: "AND",
  filters: [{ property: undefined, operator: undefined }],
};

export const DATE_FORMAT_BY_OPERATOR: Record<string, string> = {
  LESS_EQUAL: "YYYY-MM-DD 23:59:59",
  GREATER_EQUAL: "YYYY-MM-DD 00:00:00",
  LESS: "YYYY-MM-DD 00:00:00",
  GREATER: "YYYY-MM-DD 23:59:59",
};

export const CAMPAIGN_TYPE_NAME: Record<string, string> = {
  autocall: "Callbot AI Auto Call",
  telesale: "Quay số Telesales",
  sms_campaign: "Tin nhắn zalo",
};
